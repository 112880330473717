@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.dropdownContainer {
    display: inline-block;
}

.dropdown {
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    overflow: hidden;
}

.list {
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;

    :global(.dropdown-item) {
        color: color('primaryDark');
        padding: 1rem;
    }

    :global(.active),
    :global(:active) {
        background-color: color('primary');
        color: color('white');
    }
}

.button {
    background-color: transparent !important;
    border: 0 !important;
    color: color('primaryDark') !important;

    &::after {
        display: none;
    }
}
