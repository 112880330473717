@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.noItemsContainer {
    font-family: Alexandria;
    font-size: 14px;
    letter-spacing: 0px;
    color: color('light2');
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileContainer {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    margin-bottom: 0.7rem;
    padding: 10px;
    // flex: 1 1 calc(33.333% - 10px);
    // max-width: calc(33.333% - 10px);
    box-sizing: border-box;
    position: relative;
}

.imageContainer {
    width: 170px;
    height: 170px;
}

.fileRemoveIcon {
    font-size: 16px;
    background-color: color('primaryDark');
    color: color('white');
    border-radius: 4px;
    position: absolute;
    height: 30px;
    width: 30px;
    top: 20px;
    right: 20px;
    padding: 0 !important;

    svg {
        font-size: 11px !important;
    }

    &:hover {
        background-color: darken(color('primaryDark'), 5%)
    }
}

.isMainContainer {
    margin-top: 10px;
}
