@import '../utils/functions.scss';

.react-datepicker {
    font-family: 'Roboto', sans-serif;
    border: 1px solid #A5ADC6;
    border-radius: 8px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: color('primary');
}

.react-datepicker__header {
    background-color: #F5F6F9;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.react-datepicker__month-container {
    border-radius: 8px !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    stroke: #A5ADC6;
    fill: #F5F6F9;
    color: #F5F6F9;
}