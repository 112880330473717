@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-top: 1rem;

    .row {
        flex: 1;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.button {
    height: 39px;
    width: 39px;
    margin-bottom: 5px;
    padding: 0 !important;
}

.addButton {
    &:hover {
        background-color: darken(#E2E4EB, 5%)
    }
}

.control {
    flex: 1;
    margin-right: 1rem;

    &:last-child {
        margin-right: 0;
    }
}

.controlContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dragHandle {
    cursor: move !important;
    padding: 0 !important;
    margin-right: 0.7em;
}

.checkbox {
    padding-top: 39px;
    margin-right: 8px;
}
