@import "../../../styles/utils/responsive.scss";

.content {
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    background-clip: padding-box;
    background-color: #FFFFFF;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
    outline: 0 none;
    // min-width: 60%;
    max-width: 90%;
    // padding: 1rem;
    // overflow-y: auto;

    @include respond-below(lg) {
        min-width: unset;
    }
}

.modalHeader {
    padding: 1rem;
    border-bottom: 1px solid color('light');
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #FFFFFF;
    border-radius: 10px 10px 0 0;
}

.modalBody {
    padding: 0.5rem 1rem;
    overflow-y: auto;
}

.modalFooter {
    padding: 1rem;
    padding-top: 0;
    position: sticky;
    bottom: 0;
    z-index: 10;
    background-color: #FFFFFF;
    border-radius: 0 0 10px 10px;
}