$darkBlueColor: #10245F;
$lightGreyColor: #F5F6F9;

html,
body,
#root {
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: "Alexandria", sans-serif;
    font-size: 14px;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.main-wrapper {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    position: relative;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "sidebar header"
      "sidebar main";
}

.main-header {
    grid-area: header;
    padding: 0.5rem;
}

.main-container {
    grid-area: main;
    box-shadow: inset 2px 1px 9px 1px rgba($dark, 0.10);
    overflow: hidden;
    // padding-top: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
}

.ps-sidebar-root {
  padding-top: 0;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;
}

.react-datepicker-popper {
    z-index: 1001;
}

.breadcrumb {
    --bs-breadcrumb-margin-bottom: 1rem;
}

.modal {
    --bs-modal-border-radius: 0;
}

.btn,
.form-control,
.form-select {
    border-radius: 0;
}

.form-label {
    font-weight: bold;
}

.modal-title {
    text-align: center;
    flex: 1;
}

.wrapped-container {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
}

.text-right {
  text-align: right;
}

.table-responsive .global-action-column:has(> .dropdown.show) {
    z-index: 1;
}

.v-align-middle {
    vertical-align: middle;
}

.cursor-pointer {
    cursor: pointer;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: color('primary');
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: color('primaryDark');
}