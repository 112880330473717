.sidebar {
    background-color: white;
    z-index: 50;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    cursor: pointer;
}