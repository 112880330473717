@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    width: 100%;
}

.addButton {
    width: calc(100% - 48px);
    // width: 100%;
    margin-top: 1rem;
}