@import "../../../../styles/utils/functions.scss";

.modalContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.bodyContent {
    max-height: 25vh;
}

.footerContent {
    text-align: right;
}

.timeline {
    position: relative;
    margin: 10px 0;
}

.timelineItem {
    position: relative;
    padding-left: 40px;
    margin-bottom: 20px;
}

.circle {
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    background-color: color('white');
    border-radius: 50%;
    border: 1px solid color('light2');
    z-index: 1;
}

.timelineItem::before {
    content: '';
    position: absolute;
    left: 8px;
    top: 15px;
    width: 1px;
    height: 135%;
    background: color('light2');
    z-index: 0;
}

.timelineItem:last-child::before {
    height: 0;
}