@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

$rows: 3;

.quill {
    :global(.ql-editor) {
        min-height: $rows * 25px;
    }

    &.hasError {
        :global(.ql-toolbar.ql-snow) {
            border-color: color('danger')
        }
        
        :global(.ql-container.ql-snow) {
            border-color: color('danger')
        }
    }

    &.isInline {
        :global(.ql-toolbar.ql-snow) {
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }
    
        :global(.ql-container.ql-snow) {
            border: 0;
        }
    }
}
