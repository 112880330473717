@import '../../utils/functions.scss';

.nav-tabs {
    border: none;

    .nav-item {
        margin-bottom: 0;
        border: 2px solid #E5E8F2;
        border-bottom: 0;
        border-radius: 8px 8px 0px 0px;
        margin-right: 4px;
    }

    .nav-link {
        border: none;
        background-color: #EFF0F5;
        font-family: Alexandria;
        font-size: 14px;
        color: color('primary');

        &.active {
            background-color: #FFFFFF;
            color: color('primary');
        }
    }

    .nav-item:has(.nav-link:disabled) {
        cursor: not-allowed !important;
    }

    +.tab-content {
        border: 2px solid #E5E8F2;
        border-radius: 0px 8px 8px 8px;
        padding: 1rem;
    }
}
