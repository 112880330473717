@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.input {
    background-color: #FFFFFF;
    border: 1px solid color('light');
    border-radius: 8px;
    width: 100%;
    flex: 1;
    padding: 0.8rem;
    outline: 0;
    font-family: Alexandria;
    letter-spacing: 0px;
    color: color('primaryDark');
    font-size: 14px;

    &::placeholder {
        opacity: 1;
        color: color('light2');
        font-weight: 300;
        font-size: 14px;
        font-family: Alexandria;
    }

    &:focus,
    &:active {
        border: 1px solid color('light2');
        outline: 0;
    }

    &:disabled {
        background-color: color('light3');
        color: color('light2');
    }

    &.hasError {
        border-color: color('danger')
    }
}