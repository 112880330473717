@import "../../../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../../../styles/utils/responsive.scss";

.list {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 1rem;

    .productItem {
        flex: none
    }
}
