@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image {
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin-right: 0.6rem;
}

.labelContainer{
    white-space: normal;

    .title{
        display: flex;
        align-items: center;
    }
}