@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    width: 100%;
    position: relative;
}

.text {
    width: 100%;
    height: 100%;
    font-size: 14px;
    cursor: pointer;
    padding: 0 4px;

    &.disabled {
        background-color: transparent !important;
        cursor: default;
    }

    &:hover {
        background-color: color('light3');
        border-radius: 4px;

        &.disabled {
            background-color: transparent;
        }
    }
}

.dropdownContainer {
    display: block;
}

.dropdown {
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    overflow: hidden;
    // transform: translate(0px, 30px) !important;
}

.list {
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    max-height: 300px;
    overflow-y: auto;

    :global(.dropdown-item) {
        color: color('primaryDark');
        padding: 1rem;
    }

    :global(.active),
    :global(:active) {
        background-color: color('primary');
        color: color('white');
    }
}
