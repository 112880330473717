
.requiredCheckbox {
    margin-top: 1.2rem;
}

.dragIconContainer {
    text-align: center;
    display: block;
}

.dragIcon {
    margin-top: 0.8rem;
}
