@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.footerContainer {
    display: flex;
    justify-content: center;
}
.message{
    text-align: center;
    padding: 1rem 0;
}

.cancelButton{
    margin-right: 0.5rem;
}

.center{
    justify-content: center;
    padding: 1rem 0 1.5rem 0;
}