@import "../../../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../../../styles/utils/responsive.scss";

.parameter {
    margin-bottom: 0.8rem;
}

.parameterName {
    font-size: 12px;
    font-weight: bold;
    color: color('light2');
    margin-bottom: 0.2rem;
}
