@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";
@import "../Utils.module.scss";

.container {
    overflow-x: auto;
    overflow-y: auto;
    height: 100%;
}

.table {
    min-width: 900px;
    // height: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    padding-left: $tableOutsidePadding;
    padding-right: $tableOutsidePadding;
    background: color('white');
    position: sticky;
    top: -1px;
    z-index: 11;
    transform: translateY(0px);
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    transition: none;

    &.headerSticky {
        border-bottom: 1px solid color('light');
    }
}

.column {
    color: color('primaryDark');
    font-weight: bold;
    font-size: 12px;
}

.body {
    background: color('light3');
    border-radius: 8px;
    padding: $tableOutsidePadding;
    margin-bottom: 35vh;
}
