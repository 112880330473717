@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

.container {
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    @include respond-below(sm) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.labelContainer {
    flex: 1;
    // text-align: right;
    margin-right: 1rem;
}

.inputContainer {
    width: 50%;

    @include respond-below(sm) {
        width: 100%;
    }
}
