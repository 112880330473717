@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

.container {
    background-color: color("primary");
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0 0.4rem 0;
}

.appName {
    font-family: Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 0.2rem;
}

.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    color: #FFFFFF;
}

.userLinks a {
    text-decoration: none;
    margin: 0 0.4rem;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0px;
}
.userLinks a:hover {
    color: #e0e0e0;
}

.userLinks a span {
    vertical-align: middle
}

.breadcrumbs {
    flex: 1;
}
