@import "../../../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../../../styles/utils/responsive.scss";

.section {
    font-size: 18px;
    font-weight: bold;
    color: color('light7');
    margin-bottom: 0.2rem;
}

.subSection {
    font-size: 14px;
    font-weight: bold;
    color: color('dark1');
    margin-bottom: 0.2rem;
}
