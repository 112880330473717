@import '../utils/functions.scss';

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: attr(data-value);
}

.ql-editor {
    font-family: "Alexandria";
}

.ql-snow .ql-picker.ql-size {
    width: 62px;
}

.ql-toolbar.ql-snow {
    border: 1px solid color('light');
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: color('light3');
}

.ql-container.ql-snow {
    border: 1px solid color('light');
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white;

    &.ql-disabled {
        border-top: 1px solid color('light');
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: color('light3');
        color: color('light2');
    }
}

.ql-editor.ql-blank::before {
    opacity: 1;
    color: color('light2');
    font-weight: 300;
    font-size: 14px;
    font-family: Alexandria;
    font-style: normal;
}