@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    width: 100%;
}

.input {
    padding: 0;
    margin: 0;
    width: 100%;
    display: inline-block;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 0;
    position: relative;

    &.disabled {
        background-color: transparent !important;
        cursor: default;
    }

    &:hover {
        background-color: color('light3');
        border-radius: 4px;

        &.disabled {
            background-color: transparent;
        }
    }

    &[data-placeholder]::before {
        content: attr(data-placeholder);
        color: color('light2');
        font-weight: 300;
        font-size: 14px;
        font-family: Alexandria;
        // position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0;
    }

    &[contenteditable]:empty::before {
        display: block;
    }
}
