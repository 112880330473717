@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

.line {
    padding: 0;
    // padding-top: 4px;
    position: relative;
    transition: all 1s ease 0s;
    scroll-margin: 30px;
    // border: 1px solid transparent;
    // border-radius: 8px;
}

.lineClone {
    // display: inline-block;
    pointer-events: none;
    // padding: 5px;
}

.lineGhost {
    opacity: 0.5;
}

.lineGhost {
    &.indicator {
        opacity: 1;
        position: relative;
        z-index: 1;
        margin-bottom: -1px;

        .contentContainer {
            position: relative;
            padding: 0;
            height: 8px;
            border-color: #2389ff;
            background-color: #56a1f8;

            &:before {
                position: absolute;
                left: -2px;
                top: -4px;
                display: block;
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 1px solid #2389ff;
                background-color: #ffffff;
                z-index: 100;
            }

            >* {
                /* Items are hidden using height and opacity to retain focus */
                opacity: 0;
                height: 0;
            }
        }
    }

    &:not(.indicator) {
        opacity: 0.5;
    }

    .contentContainer>* {
        box-shadow: none;
        background-color: transparent;
    }
}

.lineDisableSelection {
    user-select: none;
    -webkit-user-select: none;
}

.lineDisableIteraction {
    pointer-events: none;
}

.contentContainer {
    padding-top: 8px;
}

.isBeingCut {
    opacity: 0.4;
}
