$tableOutsidePadding: 0.8rem;

.column {
    padding: 6px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.designationColumn {
    flex: 1;
    text-align: left;
    padding-left: 12px;
    display: flex;
    justify-content: flex-start;
}
.quantityColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
.unityColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
.grossUnityColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
.marginColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
.discountColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
.pricePerUnityColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
.unitPriceWithChildrenColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
.totalWithoutTaxColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
.totalWithTaxColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
.taxColumn {
    width: 100px;
    min-width: 100px;
    text-align: center;
}
