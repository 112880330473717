@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

.container {
    background-color: color('white');
    border: 1px solid color('light');
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.title {
    background-color: color('light3');
    padding: 1rem;
    border-bottom: 1px solid color('light');
    font-size: 16px;
    color: color('primaryDark');
    font-family: Alexandria;
    font-size: 16px;
}

.content {
    padding: 1rem;
}
