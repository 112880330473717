@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.button {
    border-radius: 8px;
    outline: 0;
    font-family: Alexandria;
    font-weight: normal;
    text-align: center;
    border: 1px solid transparent;

    &.fw {
        margin-right: 0.8rem;
    }

    &.primary {
        background-color: color('primary');
        border: 1px solid color('primary');
        color: color('white');

        &:hover {
            background-color: darken(color('primary'), 3%)
        }

        &:global(.dropdown-toggle.show) {
            background-color: color('primaryDark');
        }
    }

    &.empty {
        background-color: transparent;
        border: 1px solid transparent;
        color: color('primaryDark');

        &:hover {
            background-color: transparent;
        }

        &:global(.dropdown-toggle.show) {
            background-color: transparent;
        }
    }

    &.danger {
        background-color: color('danger');
        border: 1px solid color('danger');
        color: color('white');

        &:hover {
            background-color: darken(color('danger'), 3%)
        }

        &:global(.dropdown-toggle.show) {
            background-color: darken(color('danger'), 5%)
        }
    }

    &.secondary {
        background-color: color('white');
        border: 1px solid color('primary');
        color: color('primary');

        &:hover {
            background-color: darken(color('white'), 2%)
        }

        &:global(.dropdown-toggle.show) {
            background-color: darken(color('white'), 5%)
        }
    }

    &.third {
        background-color: color('white');
        border: 1px solid color('light');
        color: color('primaryDark');

        &:hover {
            background-color: darken(color('white'), 2%)
        }

        &:global(.dropdown-toggle.show) {
            background-color: darken(color('white'), 5%)
        }
    }

    &.forth {
        background-color: color('light3');
        border: 1px solid color('light');
        color: color('primary');

        cursor:default;
    }


    &:disabled {
        background-color: color('light3');
        border: 1px solid color('light');
        color: color('light2');

        &:hover {
            background-color: color('light3');
        }
    }

    &.normal {
        padding: 0.7rem 1rem;
        font-size: 14px;
    }

    &.medium {
        padding: 0.4rem 0.9rem;
        font-size: 14px;
    }

    &.small {
        padding: 0.2rem 0.6rem;
        font-size: 12px;
    }
}
