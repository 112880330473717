@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.container {
    // border: 1px solid transparent;
    // border-radius: 8px;
}

.section {
    background-color: color('light5');
    border: 1px solid color('light');
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column {
    color: color('primaryDark');
    font-size: 14px;
    font-weight: bold;
}

.designationColumn {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggleOpenButton {
    margin-right: 5px;
    padding: 0px 3px !important;
    border-radius: 6px !important;
    font-size: 10px !important;
}

@for $level from 1 through 4 {
    .level-#{$level} {
        background-color: lighten(color('light5'), $level * 2.5%);
    }
}

.number {
    margin-right: 3px;
}

.addLinesDiv{
    padding:10px;
    margin-top: 10px;
}