@import "../../../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../../../styles/utils/responsive.scss";

.container {
    // margin-top: 1rem;
}

.childrenContainer {
    background-color: color('light3');
    border: 1px solid color('light');
    padding: 1rem;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
