@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.sideButton {
    background-color: color('light3');
    border: 1px solid color('light');
    border-radius: 8px;
    color: color('primaryDark');
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    margin-left: -12px;
    z-index: 10;
    cursor: pointer;

    &:hover {
        background-color: color('light4');
    }

    &.notClickable {
        cursor: default;

        &:hover {
            background-color: color('light3');
        }
    }

    &.sideButtonExtraLeft {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.sideButtonExtraRight {
        margin: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }
}
