@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.note {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: color('white');
    border: 1px solid color('light');
    border-radius: 8px;
}

.designationColumn {
    padding: 0;
}

.input {
    flex: 1;
}
