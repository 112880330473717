@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.table {
  background-color: color("white");
  width: 100%;
  border: 0;
  border-collapse: separate;
  border-spacing: 0;

  tbody {
    display: block;
    overflow-y: auto;
    max-height: 60vh;

    tr{
      display: flex;

      td{
        width: 100%;
      }
    }
  }
}

.searchContainer {
  padding: 10px 15px;
}

.tableHeader {
  padding: 1rem;
  display: flex;
  border-bottom: 1px solid color("gallery");
}

.tableHeaderTitle {
  flex: 1;
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .expressionContainer {
    flex: 1;
  }

  .configContainer {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 50vh;

    .rulesContainer {
      flex: 3;
      margin: 15px;
      width: 100%;
      border: 1px solid color("gallery");
      border-radius: 10px;
      .ruleContainer {
        display: flex;
        align-items: center;
        .ruleInfo {
          padding: 1rem;
        }
      }
    }
    .usersContainer {
      flex: 3;
      width: 100%;
      margin: 15px;
      border: 1px solid color("gallery");
      border-radius: 10px;
      .userContainer {
        display: flex;
        border-bottom: 1px solid color("gallery");
        align-items: center;
        padding: 0.5rem 1rem;
        .userInfo {
          flex: 1;
          display: flex;
          flex-direction: column;
          .name {
            flex-grow: 1;
          }
          .email {
            font-size: 80%;
          }
        }
        .selected {
          padding-top: 6px;
        }
      }
    }

    .rolesContainer {
      flex: 3;
      width: 100%;
      margin: 15px;
      border: 1px solid color("gallery");
      border-radius: 10px;
      .roleContainer {
        display: flex;
        border-bottom: 1px solid color("gallery");
        align-items: center;
        padding: 0.5rem 1rem;
        .roleInfo {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        .checkbox {
          padding-top: 6px;
        }
      }
    }
    .operationsContainer {
      min-width: 3.375rem;
      display: flex;
      justify-content: center;
      margin: 4rem 0.938rem 0.938rem 0.938rem;
      .multiButton {
        display: flex;
        flex-direction: column;
        button {
          margin: 0;
          flex: 1;
          border-radius: 0;
        }
      }
    }
    .rowHover:hover {
      cursor: pointer;
      transition: 0.2s;
    }
    .striped {
      background-color: color("primary");
      color: color("white");
    }

    .columnHeader {
      padding: 0.5rem 1rem;
      color: color("alto");
      font-size: 0.875rem;
      overflow: hidden;
      text-overflow: ellipsis;
      @include fontWeight("normal");
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 20px;
}

.infoCircle {
  cursor: pointer;
  margin-top: -2px;
}

@include respond-below(lg) {
  .configContainer {
    flex-direction: column;
    .rulesContainer,
    .rolesContainer,
    .usersContainer,
    .operationsContainer {
      margin: 15px 0;
    }
    .operationsContainer {
      width: 100%;
    }
  }
}

