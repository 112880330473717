@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.cardContainer {
    display: flex;
    flex-direction: column;
    width: 170px;
    padding: 0;
    margin: 0 10px;
    cursor: pointer;
    border: solid 1px transparent;
    border-radius: 8px;
    background-color: white;

    &:first-child {
        margin-left: 0;
    }

    .cardHeader {
        position: relative;
        border: solid 1px transparent;
        border-radius: 8px;
        height: 10rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: color('white');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        .loadingContainer {
            z-index: 999;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .cardHeaderImage {
            position: absolute;
            width: 100%;
            height: 100%;

            .image {
                width: 100%;
                height: 100%;
            }
            .noImage {
               background-color: white;
            }
        }

        .cardHeaderTop {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: flex-start;
            z-index: 1;
        }

        .cardHeaderBottom {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: flex-end;
            z-index: 1;
        }

        .button {
            cursor: pointer;
            padding: 5px;
            border-radius: 8px;

            &:hover {
                background-color: rgba(color('light'), 0.5);
            }
        }
    }

    .cardBody {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 5px 5px 0;

        .cardFamily {
            color: color('light2');
            font-size: 0.8rem;
        }
    }

    .cardFooter {
        display: flex;
        flex-direction: column;
        padding: 5px;

        .cardPrice {
            font-weight: bold;
        }

        .cardQuantity {
            margin-top: 10px;
        }
    }

    &.selected {
        background-color: color('primary');

        .cardHeader {
            border-color: color('primary');
        }

        .cardBody {
            .cardName {
                color: color('white');
            }

            .cardFamily {
                color: color('white');
            }
        }

        .cardFooter {
            color: color('white');
        }
    }
}

.bottomArrow {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 15px solid color('primary');
    border-bottom: 0;
}
