@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.sectionsContainer {
    margin: 5rem 0 !important;
}

.marginBottomMd{
    @include respond-below(md) {
      margin-bottom:1rem;
    }
}

.marginBottomSm{
    @include respond-below(sm) {
      margin-bottom:1rem;
    }
}

.totalPriceButton{

  .totalPriceLabel{
    padding-right: 1.25rem;
  }
  .totalPrice{
    font-weight: bold;
  }
}

.infoIcon{
  margin-bottom:3px;
}

.dateTime {
  color: color('light2');
  margin-bottom: 0;
}

.price {
  font-weight: bold;
  margin-bottom: 0;
}
