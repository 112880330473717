@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.label {
    color: color('primaryDark');
    font-family: Alexandria;
    font-size: 13px;
    font-weight: bold;
    display: block;
}

.text {
    color: color('primaryDark');
    font-family: Alexandria;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
}

.space {
    margin-bottom: 0.6rem;
}

.header {
    color: color('primary');
    font-family: Alexandria;
    font-size: 20px;
    font-weight: bold;
    display: block;
    margin-bottom: 0;
    text-decoration: none;

    &:hover {
        color: color('primary');
    }
}

.subHeader {
    color: color('primary');
    font-family: Alexandria;
    font-size: 13px;
    font-weight: bold;
    display: block;
    margin-bottom: 0;
    text-decoration: none;

    &:hover {
        color: color('primary');
    }
}

.informationText {
    color: color('light2');
    font-family: Alexandria;
    font-size: 16px;
    font-weight: normal;
    display: block;

    &.small {
        font-size: 11px;
    }

    &.medium {
        font-size: 14px;
    }
}
