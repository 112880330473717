@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.table {
    background-color: color("white");
    width: 100%;
    max-width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 4px;

    th::before {
        content: '';
        display: block;
        width: var(--column_resize_before_width);
    }
}

.columnResizer {
    width: 20px !important;
    min-width: 20px !important;
    max-width: 20px !important;

    &:not(.disabled_column_resize):hover::after {
        content: url('/assets/svg/column-resize.svg');
        position: absolute;
        left: 5px;
        top: calc(50% - 6px);
        width: 13px;
    }
}

.columnResizerBody {
    border-top: 1px solid color('light');
    border-bottom: 1px solid color('light');
}

.columnResizer, .columnHeader {
    position: sticky;
    top: -1px;
    z-index: 1;
    background-color: color('white');

    &.isSticky {
        border-bottom: 1px solid color('light');
    }
}

.columnHeader {
    padding: 0.2rem 0.2rem;
    color: color("alto");
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include fontWeight("normal");
    font-family: Roboto;
    letter-spacing: 0px;
    color: color('primaryDark');
    font-weight: bold;
    vertical-align: top;
}

.filterColumn {
    padding-top: 0.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column {
    padding: 0.8rem 1rem;
    word-break: keep-all;
    // position: relative;
    // overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    border-top: 1px solid color('light');
    border-bottom: 1px solid color('light');

    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-left: 1px solid color('light');
    }

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-right: 1px solid color('light');
    }
}

.row {
    &.even {
        background-color: color('light3');

        td {
            background-color: color('light3');
        }
    }

    &.odd {
        background-color: #FAFBFC;

        td {
            background-color: #FAFBFC;
        }
    }

    &.rowHover:hover {
        cursor: pointer;
        transition: 0.2s;

        .column, .columnResizerBody {
            background-color: color('light6');
        }
    }
}

.selected {
    background-color: color("alabaster");
}

.striped {
    // background-color: color("gallery");
}

.noItemsText {
    padding: 2rem;
    text-align: center;
    color: color('light2');
    font-family: Alexandria;
    font-size: 14px;
    font-weight: normal;
    background-color: color('light3');
    border: 1px solid #E5E8F2;
    border-radius: 4px;
}

.sm {
    @include respond-below(sm) {
        display: none !important;
    }
}

.md {
    @include respond-below(md) {
        display: none !important;
    }
}

.lg {
    @include respond-below(lg) {
        display: none !important;
    }
}

.xl {
    @include respond-below(xl) {
        display: none !important;
    }
}

.xxl {
    @include respond-below(xxl) {
        display: none !important;
    }
}
.mobile {
    font-size: 0.875rem !important;
}

.headerInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    word-break: keep-all;

    .headerSortIcon {
        margin-right: 0.3rem;
        opacity: 0.2;
        color: color('primaryDark');

        &.active {
            opacity: 1;
        }
    }

    &.headerInfoRight {
        justify-content: flex-end;
    }
}

.clicakble {
    cursor: pointer;
}

.clearFiltersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clearFiltersButton {
    background-color: color('primary');
    border-radius: 4px;
    padding: 8px;
    color: #ffffff;
    font-size: 14px;
    height: 28px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.clearFiltersText {
    font-family: Roboto;
    letter-spacing: 0px;
    color: color('primaryDark');
    font-size: 10px;
    text-wrap: nowrap;
}

.tableResponsive {
    height: 100%;
    @include scrollbars();
}

.actionColumnHeader {
    background-color: color('white');
}

.actionColumnHeader,
.actionColumn {
    text-align: center;
    width: 45px;
    position: sticky;
    right: -1px;
    padding-left: 0;
    padding-right: 0;

    &.rightIsSticky {
        border-left: 1px solid color('light');
    }
}

.clickable {
    cursor: pointer;
}
