@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container{
    position: relative;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    text-align: center;
}

.infoMessage{
    background-color: color('infoBackGround') ;
    color: color('infoText');
    border-color: color('infoBorder');
}

.successMessage{
    background-color: color('successBackGround') ;
    color: color('successText');
    border-color: color('successBorder');
}

.warningMessage{
    background-color: color('warningBackGround') ;
    color: color('warningText');
    border-color: color('warningBorder');
}

.errorMessage{
    background-color: color('errorBackGround') ;
    color: color('errorText');
    border-color: color('errorBorder');
}

.title{
    font-weight: 800;
}

.closeButton{
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
}