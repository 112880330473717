@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.datepicker {
    flex: 1;
    display: block;
}

.input {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid color('light');
    border-radius: 8px;
    padding: 0.8rem;
    outline: 0;
    font-family: Alexandria;
    letter-spacing: 0px;
    color: color('primaryDark');
    font-size: 14px;

    &::placeholder {
        opacity: 1;
        color: color('light2');
        font-weight: 300;
        font-size: 14px;
        font-family: Alexandria;
    }

    &:focus,
    &:active {
        border: 1px solid color('light2');
        outline: 0;
    }

    &:disabled {
        background-color: color('light3');
        color: color('light2');
    }

    &.inputGroupEnd {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.sideButton {
    background-color: color('light3');
    border: 1px solid color('light');
    border-radius: 8px;
    color: color('primaryDark');
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    margin-left: -12px;
    z-index: 10;
    cursor: pointer;

    &:hover {
        background-color: color('light4');
    }

    &.sideButtonGroupStart {
        border-right: 0;
        border-radius: 0;
    }
}