@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.noItemsContainer {
    font-family: Alexandria;
    font-size: 14px;
    letter-spacing: 0px;
    color: color('light2');
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: color('light3');
    border: 1px solid color('light');
    border-radius: 8px;
    padding: 0.8rem;
    margin-bottom: 0.7rem;
}

.fileIcon {
    color: color('primaryDark');
    font-size: 16px;
    margin-right: 4px;
}

.fileText {
    margin-right: 10px;
    font-size: 14px;
    color: color('primaryDark');
}

.fileRemoveIcon {
    background-color: color('primaryDark');
    color: color('white');
    border-radius: 4px;
    padding: 0 !important;
    width: 24px;
    height: 24px;

    svg {
        font-size: 10px !important;
    }

    &:hover {
        background-color: darken(color('primaryDark'), 5%)
    }
}
