@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

$sidebarWidthLg: 18vw;
$sidebarWidthMd: 28vw;
$sidebarWidthXs: 80vw;

.sidebar {
    position: absolute;
    top: 4px;
    height: 100%;
    right: -$sidebarWidthXs;
    width: $sidebarWidthXs;
    transition: right 0.3s ease-in-out;
    background-color: lightgray;
    display: flex;
    flex-direction: column;

    @media (min-width: 576px) {
        right: -$sidebarWidthXs;
        width: $sidebarWidthXs;
    }

    @media (min-width: 768px) {
        right: -$sidebarWidthXs;
        width: $sidebarWidthXs;
    }

    @media (min-width: 992px) {
        right: -$sidebarWidthMd;
        width: $sidebarWidthMd;
    }

    @media (min-width: 1200px) {
        right: -$sidebarWidthMd;
        width: $sidebarWidthMd;
    }

    @media (min-width: 1536px) {
        right: -$sidebarWidthLg;
        width: $sidebarWidthLg;
    }

    &.isOpen {
        right: 0;
    }
}

.toggle {
    position: absolute;
    top: 0;
    left: -60px;
    height: 60px;
    width: 60px;
    z-index: 1;
    background-color: color('light3');
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid color('light');
    border-right: 0;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    cursor: pointer;
    color: color('primary');
}

.content {
    flex: 1;
    background-color: color('light3');
    border: 1px solid color('light');
    padding: 1rem;
    z-index: 20;
    overflow-y: auto;
}

.title {
    color: color('primary');
    font-family: Alexandria;
    letter-spacing: 0px;
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 1rem;
}

.list {
    display: flex;
    flex-direction: column;
}

.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: color('light2');
    margin-bottom: 0.7rem;
    cursor: pointer;

    &.listItemActive {
        color: color('primary');
    }

    .itemIcon {
        margin-right: 0.3rem;
    }

    .itemText {
        margin-top: 3px;
        flex: 1;
    }
}

.columnConfigCursor {
    padding: 0.6rem 1rem;
}