@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

.container {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titles {
    flex: 1;
}

.iconContainer {
    background-color: #FFFFFF;
    border: 1px solid color('light');
    border-radius: 8px;
    color: color('primary');
    font-size: 19px;
    height: 50px;
    width: 50px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sidebarSpacing {
    margin-right: 60px;
}

.header {
    margin-right: 0.6rem;
}
