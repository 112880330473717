@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.container {
    // border: 1px solid transparent;
    // border-radius: 8px;
}

.element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: color('white');
    border: 1px solid color('light');
    border-radius: 8px;
}

.designationColumn {
    display: flex;
    flex-direction: row;
    align-items: center;
    // overflow: hidden;
}

.number {
    margin-right: 3px;
}

.designationWithLevel {
    display: flex;
    flex-direction: row;
}

.designationInformation {
    flex: 1;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
}

.designation {
    font-weight: bold;
    font-size: 14px;
    color: color('primaryDark');
}

.information {
    font-size: 14px;
    color: color('light2');
    word-break: break-word;
}

.editionRow {
    flex: 1;
    width: 100%;
    padding: 0.6rem;
}

.editionAddButton {
    height: 48px;
}

.columnsContainer {
    width: 100%;
}

.columnsInnerContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.image {
    width: 70px;
    height: 70px;
}
