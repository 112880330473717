@import "../../../../styles/utils/functions.scss";

.form {
    display: flex;
    flex-direction: column;
    height: 100%;

    :global(.tab-content) {
        height: 100%;
        overflow: hidden;
        flex: 1;
        position: relative;
    }

    :global(.tab-pane) {
        height: 100%;
    }
}

.totalPriceButton{

    .totalPriceLabel{
      padding-right: 1.25rem;
    }
    .totalPrice{
      font-weight: bold;
    }
  }

.historyVersion {
  display: inline-block;
  border: solid 1px color('light2');
  background-color: color('light3');
  padding: 2px 7px;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.dateTime {
  color: color('light2');
  margin-bottom: 0;
}

.button {
  border: none;
  outline: 0;
  background-color: transparent;
  color: color('primaryDark');
  font-size: 12px;
  width: 33px;
  height: 33px;
  margin: 0.2rem;

  &:hover {
      background-color: darken(color('light6'), 5%)
  }
}

.versionWarning {
  margin-bottom: 1rem;
}