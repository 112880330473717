@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    width: 100%;
}

.addButton {
    width: 100%;
    margin-top: 1rem;

    &.isChild {
        width: auto;
    }
}