@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    flex: 1;
    cursor: pointer;
}

.inputFill {
    flex: 1;
    padding: 0.8rem;
    background-color: #FFFFFF;
    border: 1px solid color('light');
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.hasError {
        border-color: color('danger')
    }
}

.name {
    font-family: Alexandria;
    letter-spacing: 0px;
    color: color('primaryDark');
    font-size: 14px;
}

.placeholder {
    opacity: 1;
    color: color('light2');
    font-weight: 300;
    font-size: 14px;
    font-family: Alexandria;
}

.sideButton {
    background-color: color('light3');
    border: 1px solid color('light');
    border-radius: 8px;
    color: color('primaryDark');
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    margin-left: -12px;

    &.sideButtonExtraLeft {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.sideButtonExtraRight {
        margin: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }
}

.container.disabled .name {
    color: color('light2');
}

.container.disabled .inputFill {
    background-color: color('light3');
}