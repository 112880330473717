@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    display: block;
    position: relative;
    padding-left: 30px;
    // margin-bottom: 28px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    min-height: 25px;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 1px solid color('light');
}

.container input:checked~.checkmark {
    // background-color: #2196F3;
    border: 1px solid color('primary');
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
    display: block;
}

.container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: color('primary');
}

.container input:disabled~.checkmark {
    background-color: color('light3');
    border-color: color('light');
}

.container input:checked:disabled~.checkmark:after {
    background-color: color('light2');
}

.labelText {
    font-size: 14px;
    font-weight: normal;
    font-family: Alexandria;
    color: color('primaryDark');
    vertical-align: middle;
    margin-right: 0.5rem;
}
