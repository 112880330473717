@import "../../../styles/utils/functions.scss";

.image {
    background-color: color('white');
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    min-width: 60px;
    min-height: 60px;
    overflow: hidden;
    margin-right: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.noImage {
        background-color: color('light3');
        padding: 10px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        overflow: hidden;

        &.noImage {
            max-width: 99px;
            max-height: 99px;
        }
    }
}