@import "../../../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../../../styles/utils/responsive.scss";

.list {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    
    .item {
        flex: none
    }
}

.image {
    width: 200px;
    height: 200px;
}