@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.contentContainer {
    padding-top: 8px;
}

.line {
    padding: 0;
    // padding-top: 4px;
    position: relative;
    transition: all 1s ease 0s;
    // border: 1px solid transparent;
    // border-radius: 8px;
}

.lineClone {
    // display: inline-block;
    pointer-events: none;
    // padding: 5px;
}

.lineGhost {
    opacity: 0.5;
    &.indicator {
        opacity: 1;
        position: relative;
        z-index: 1;
        margin-bottom: -1px;

        .contentContainer {
            position: relative;
            padding: 0;
            height: 8px;
            border-color: #2389ff;
            background-color: #56a1f8;

            &:before {
                position: absolute;
                left: -2px;
                top: -4px;
                display: block;
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 1px solid #2389ff;
                background-color: #ffffff;
                z-index: 100;
            }

            >* {
                /* Items are hidden using height and opacity to retain focus */
                opacity: 0;
                height: 0;
            }
        }
    }

    &:not(.indicator) {
        opacity: 0.5;
    }

    .contentContainer>* {
        box-shadow: none;
        background-color: transparent;
    }
}

.lineDisableSelection {
    user-select: none;
    -webkit-user-select: none;
}

.lineDisableIteraction {
    pointer-events: none;
}

.container {
    margin-top: 0.5rem;
}

.section {
    background-color: color('light3');
    border: 1px solid transparent;
    border-radius: 8px;
}

.header {
    background-color: color('light5');
    border: 1px solid color('light');
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 6px 12px;
    color: #10245F;
    font-size: 14px;
    font-weight: bold;

    @include respond-below(lg) {
        flex-direction: column;
    }

    &.active {
        border-top-left-radius: 0;
    }

    .title {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 25.5px;
    }

    .rightContent {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}

.toggleOpenButton {
    margin-right: 5px;
    padding: 3px 6px !important;
    border-radius: 6px !important;
    font-size: 10px !important;
}

@for $level from 1 through 4 {
    .level-#{$level} {
        background-color: lighten(color('light5'), $level * 2.5%);
    }
}

