@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.message {
    display: block;
    font-size: 14px;
    margin: 4px 0;
    color: color('danger');
}

.messageWarning {
    color: color('warning') !important;
    font-size: 14px;
}