@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 255px;

    @include respond-below(sm) {
        flex-direction: column;
        height: auto;
    }
}

.uploaderContainer {
    background-color: color('light3');
    border: 2px dashed color('light');
    border-radius: 8px 0px 0px 8px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond-below(sm) {
        border-radius: 8px 8px 0px 0px;
        width: 100%;
        padding: 1.8rem 0;
    }

    &.dragActive {
        border-color: color('primary');
    }

    .icon {
        font-size: 40px;
        margin-bottom: 14px;
        color: color('light2');
    }

    .dropText {
        font-family: Alexandria;
        font-size: 14px;
        letter-spacing: 0px;
        color: color('primary');
        margin-bottom: 0px;
    }

    .orText {
        font-family: Alexandria;
        font-size: 14px;
        letter-spacing: 0px;
        color: color('light2');
        margin-bottom: 6px;
    }
}

.filesContainer {
    flex: 1;
    background-color: color('white');
    border: 1px solid color('light');
    border-radius: 0px 8px 8px 0px;

    @include respond-below(sm) {
        border-radius: 0px 0px 8px 8px;
        width: 100%;
        border: 1px solid color('light');
        border-top: 0;
        min-height: 50px;
    }
}

.borderLeftNone{
    border-left: 0;
}

.hasError {
    border-color: color('danger');
}
