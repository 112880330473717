@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.image {
    max-height: 60dvh;
}


.image_full_screen {
    max-height: 85dvh;
}