@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.pageBreak {
    text-align: center;
    background-color: color('light6');
    border: 2px dashed color('light2');
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: 0px;
    color: color('primaryDark');
}
