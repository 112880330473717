
.form {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    :global(.tab-content) {
        height: 100%;
        overflow: hidden;
        flex: 1;
        position: relative;
    }

    :global(.tab-pane) {
        height: 100%;
    }
}
