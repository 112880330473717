@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

 .buttonsContainer {
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1.4;

        @include respond-below(lg) {
            flex: 1.2;
            margin-left: 0.5rem;
            margin-top: 2rem;
            width: 100%;
        }

        .button {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;           

            @include respond-below(lg) {                
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
            }
        }
    }