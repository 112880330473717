@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    position: relative;
    margin: 0;
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0 4px;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;

    &.isEditing {
        cursor: text;
    }

    &.disabled {
        background-color: transparent !important;
        cursor: default;
    }

    &:hover {
        background-color: color('light3');
        border-radius: 4px;

        &.disabled {
            background-color: transparent;
        }
    }

    &.clickable {
        cursor: pointer;
    }
}
