@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.inputsContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.inputContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid color('light');
    border-radius: 8px;
    overflow: hidden;

    &.disabled {
        background-color: color('light3');
    }

    &.hasError {
        border-color: color('danger')
    }

    &:first-child {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }


}

.input {
    margin: 0;
    padding: 0.6rem 1rem;
    padding-top: 0;
    text-align: left;
    border: 0;
    outline: 0;
    font-family: Alexandria;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0px;
    color: color('primaryDark');
    width: 100%;
}

.label {
    padding-left: 1rem;
    padding-top: 0.4rem;
}

.sideButton {
    background-color: color('light3');
    border: 1px solid color('light');
    border-radius: 8px;
    color: color('primaryDark');
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    margin-left: -12px;
    height: 100%;

    &.clickable {
        cursor: pointer;

        &:hover {
            background-color: color('light4');
        }
    }

    &.sideButtonGroupStart {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

     &.hasError {
        border-color: color('danger')
    }
}
.dropdownContainer {
    display: inline-block;
}

.dropdown {
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    overflow: hidden;
}


.list {
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    max-height: 300px;
    overflow-y: auto;

    :global(.dropdown-item) {
        color: color('primaryDark');
        padding: 1rem;
    }

    :global(.active),
    :global(:active) {
        background-color: color('primary');
        color: color('white');
    }
}
