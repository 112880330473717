@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";
@import "../../styles/theme/variables.scss";

.list {
    margin-top: 1rem;
}

.headerLink {
    text-decoration: none;
    color: unset;
}

.menuItem {
    background: #F5F6F9;
    border: 1px solid #E5E8F2;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    cursor: pointer;
    padding: 1rem;

    &:hover {
        background: #f2f3f5;
    }

    .menuItemTitle {
        text-transform: uppercase;
        font-size: 18px;
        color: color('primaryDark');
        font-weight: bold;
    }

    .menuItemIcon {
        margin-top: 2rem;
        margin-bottom: 2rem;
        min-height: 60px;
    }
}
