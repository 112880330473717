@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

$buttonSize: 30px;

@keyframes show {
    100% {
        opacity: 1;
    }
}

.container {
    z-index: 10;
    position: relative;
    // position: absolute;
    // top: -$buttonSize;
    // left: 0;
    // z-index: 10;
    // Prestate
    opacity: 0;
    // animation: show 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
    animation: show linear 0.5s forwards;
}

.buttons {
    display: flex;
    flex-direction: row;
}

.button {
    border: 0;
    outline: 0;
    background-color: color('light6');
    border: 1px solid color('primary');
    border-bottom: 0;
    border-radius: 8px 8px 0px 0px;
    color: color('primaryDark');
    font-size: 12px;
    width: $buttonSize;
    height: $buttonSize;
    margin-right: 3px;

    &:hover {
        background-color: darken(color('light6'), 5%)
    }

    &.active {
        background-color: color('primary');
        color: color('white');
    }

    &.handleButton {
        cursor: move;
    }

    &:disabled {
        background-color: color('light');
        opacity: 0.2;
    }

    &.passiveDisabled {
        cursor: default;
    }
}

.menu {
    position: absolute;
    background-color: color('white');
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid color('light');
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: $buttonSize + 1px;

    .menuItem {
        text-align: left;
        font-size: 14px;
        color: color('primaryDark');
        padding: 0.6rem 1rem;
        cursor: pointer;

        &:hover {
            background-color: color('light4');
        }
    }
}
