@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.6rem;

    &:last-child {
        margin-bottom: 0;
    }

    &.isChild {
        margin-top: 1rem;
    }
}

.header {
    display: flex;
    flex-direction: row;

    .headerLeft,
    .headerRight {
        display: flex;
        flex-direction: row;
        flex: 1;
    }

    .headerRight {
        justify-content: flex-end;
    }

    .headerButton {
        background: #E2E4EB;
        border: 1px solid color('light');
        border-radius: 8px 8px 0px 0px;
        color: color('primaryDark');
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        margin-right: 4px;

        &:hover {
            background-color: darken(#E2E4EB, 5%)
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.innerContainer {
    background-color: color('light3');
    border: 1px solid color('light');
    border-radius: 0px 0px 8px 8px;
    padding: 1rem;

    &.disabled {
        border-radius: 8px;
    }

    &.hasClearBackground {
        background-color: color('white');
    }
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-content: center;

    .titleButtonContainer {
        padding-right: 3.6rem;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: 36px;
        width: 36px;
    }

    .titleControl {
        flex: 1;
    }
}

.disabled .titleButtonContainer {
    display: none;
}

.dragHandle {
    cursor: move !important;
}

.sectionClosedPlaceholder {
    padding: 0.2rem;
}
