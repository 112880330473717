@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.tabs {
    border: 0;
    padding-left: 0.9rem;

    :global {
        .nav-item {
            border: 0;

            .nav-link {
                background-color: color('light3');
                font-size: 12px;
                color: color('primary');

                &.active {
                    background-color: color('primary');
                    color: color('white');
                    font-weight: normal;
                }
            }
        }
    }

    & + :global(.tab-content) {
        border-bottom: 0;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
        border-color: color('light');
        border-width: 1px;
    }
}
