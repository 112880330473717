@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

.container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: 100%;
}

.bottomSpace {
    padding-bottom: $bottomSpace;
}
