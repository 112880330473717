@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";


.marginBottomMd{
    @include respond-below(md) {
      margin-bottom:1rem;
    }
}

.marginBottomSm{
    @include respond-below(sm) {
      margin-bottom:1rem;
    }
}

