@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.input {
    margin: 0;
    padding: 0.6rem 1rem;
    padding-top: 0;
    text-align: left;
    border: 0;
    outline: 0;
    font-family: Alexandria;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0px;
    color: color('primaryDark');
    width: 100%;
}

.noItemsText {
    padding: 2rem;
    text-align: center;
    color: color('light2');
    font-family: Alexandria;
    font-size: 14px;
    font-weight: normal;
    background-color: color('light3');
    border: 1px solid #E5E8F2;
    border-radius: 4px;
}

.addButton {
    width: 100%;
    margin-top: 1rem;
}
